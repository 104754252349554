/** @jsx jsx */
import React, { useEffect } from 'react'

import Amplify from "@aws-amplify/core";
import Analytics from '@aws-amplify/analytics';
import { API, graphqlOperation, Cache } from "aws-amplify"

import { ListAllAssets, ListShotAssetsDeltasIndex, ListShotAssetsDateDeltasIndex, listShotwireAssetsDeltasPublic, listAssetsByCategoryPublic  } from "../graphql/shotassetQueries"
import { listShotwireAssetsDeltas, listAssetsByCategory, listAssetsByUploader } from "../graphql/queries"

import { Flex, Box, jsx } from 'theme-ui'
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Spinner from 'react-bootstrap/Spinner'
import VideoCard from '../@geocine/gatsby-theme-organization/components/VideoCard'
import Layout from '../@geocine/gatsby-theme-organization/components/Layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft, faSpinner} from "@fortawesome/free-solid-svg-icons";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
import awsconfig from "../aws-exports";

import 'src/css/theme.scss'
import favicon from '../@geocine/gatsby-theme-organization/assets/favicon.ico'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import { AppsContext } from '../AppsProvider'

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false



// http://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/9519550.jpg
Amplify.configure(awsconfig); // Configure Amplify

const colorWhite50 = "rgba(255,255,255, .5)"
const colorBlack50 = "rgba(0,0,0, .5)"
const colorBlack80 = "rgba(0,0,0, .8)"
const colorWhiteHover = "rgba(255,255,255, .7)"
const colorSubLinkBackground = "rgba(0,0,0, .1)"

const linkStyle = {
  color: 'black',
	float: 'left',
	padding: '8px 16px',
	textDecoration: 'none',
	transition: 'backgroundColor .3s',
	':hover': '',
}

// export const listShotwireAssetsDeltas = /* GraphQL */ `
//   query ListShotwireAssetsDeltas(
//     $filter: ModelShotwireAssetsDeltaFilterInput
//     $limit: Int
//     $nextToken: String
//   ) {
//     listShotwireAssetsDeltas(
//       filter: $filter
//       limit: $limit
//       nextToken: $nextToken
//     ) {
//       items {
//         id
//         assetId
//         assetType
//         fileName
//         fileInfo
//         fileSize
//         highRezFileName
//         highRezDirectory
//         name
//         realSetId
//         setDate
//         companyId
//         caption
//         headline
//         instructions
//         objectName
//         source
//         copyright
//         keywords
//         byline
//         fixtureId
//         suppCat
//         captionWriter
//         city
//         state
//         country
//         countryCode
//         originalTransmissionReference
//         releaseDate
//         createdDate
//         modified
//         video_format
//         video_bitrate
//         video_width
//         video_height
//         video_fps
//         video_aspect
//         audio_codec
//         audio_format
//         audio_bitrate
//         audio_rate
//         audio_nch
//         length
//         video_frames
//         video_length
//         audio_length
//         archived
//         s3srcUrl
//         proxyInfo
//         apiSource
//         thumbNailUrl
//         mp4Outputs
//         mp4Urls
//         hlsUrl
//         dashUrl
//         workflowStatus
//         category
//         searches
//         uploader
//         timestamp
//         license
//         marketplace
//         srcMediainfo
//       }
//       nextToken
//     }
//   }
// `;

// const ListAllAssets = `query ListAllAssets($coId: String!, $searchStr: String!, $searchType: String!, $start: Int, $limit: Int) {
//   listAllAssetsFTSearch(coId: $coId, searchStr: $searchStr, searchType: $searchType, start: $start, limit: $limit) {
//     id
//     fileName
//     fileInfo
//     fileSize
//     assetType
//     highRezFileName
//     companyId
//     name
//     setDate
//     realSetId
//     assetId
//     headline
//     caption
//     keywords
//     byline
//     suppCat
//     city
//     video_length
//   }
// }
// `
//     const ListShotAssetsDeltasIndex = `query ListSAssetsDeltasIndex($category: String!,$filter: TableShotwireAssetsDeltaFilterInput, $limit: Int, $nextToken: String, $scannedCount: Int){
//       listShotAssetsDeltasIndex(category: $category, filter: $filter ,limit: $limit, nextToken: $nextToken, scannedCount: $scannedCount){
//         items {
//           id
//           category
//           setDate 
//           name
//           fileName
//           fileInfo
//           fileSize
//           assetType
//           highRezFileName
//           companyId
//           realSetId
//           assetId
//           headline
//           caption
//           keywords
//           byline
//           suppCat
//           city
//           video_length

//         }
//         nextToken
//         scannedCount
//       }
//     }`

//         const ListShotAssetsDateDeltasIndex = `query ListSAssetsDeltasIndex($category: String!,$filter: TableShotwireAssetsDeltaFilterInput, $limit: Int, $nextToken: String, $scannedCount: Int){
//       listShotAssetsDateDeltasIndex(category: $category, filter: $filter ,limit: $limit, nextToken: $nextToken, scannedCount: $scannedCount){
//         items {
//           id
//           category
//           setDate 
//           name
//           fileName
//           fileInfo
//           fileSize
//           assetType
//           highRezFileName
//           companyId
//           realSetId
//           assetId
//           headline
//           caption
//           keywords
//           byline
//           suppCat
//           city
//           video_length
//           archived
//           category

//         }
//         nextToken
//         scannedCount
//       }
//     }`


const SearchPage = ({location}) => {
  let isAdmin = false
  let userInfo = null, uRoles = null, uGroups = null

  const appsContext = React.useContext(AppsContext)

  const currentCategory = ""

	const pageNumbers = [1,2,3,4,5,6,7,8,9,10]

	const pages = [1,2,3,4,5,6,7,8];

  const terms = (appsContext) ? appsContext.state.searchform.terms : ''
  const currentPage = (appsContext) ? appsContext.state.currentPage : 1

  let activePage = 1
  	

  	const setCurrentPage = (num) => {
  		appsContext.state.currentPage = num
  		appsContext.state.activePage = num
  		appsContext.state.start = (appsContext.state.activePage-1)*appsContext.state.limit+1
  		// console.log('state.activePage:', state.activePage)
  		// console.log('state.start:',state.start)
  	}
  	const handlePageClick = (e, index) => {
	   e.preventDefault();
	   setCurrentPage(index);
     activePage =  index 
     // appsContext.dispatch({ type: "SEARCHPAGE", activePage });
	};

	const handleNextPageClick = (e, index) => {
	   e.preventDefault();
	   appsContext.state.isLoading = 1
	   if(index <= 15) setCurrentPage(index+1);
	   activePage =  index + 1
     // appsContext.dispatch({ type: "SEARCHPAGE", activePage });
	};

	const handlePrevPageClick = (e, index) => {
	   e.preventDefault();
	   appsContext.state.isLoading = 1
	   if(index > 1) setCurrentPage(index-1)	   
     activePage =  index - 1
     // appsContext.dispatch({ type: "SEARCHPAGE", activePage })
	};

	// const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
      // console.log('get for user product and stats...')
      let isMounted = true
      if(isMounted === true){
        // console.log('setting up stripe element...')

          if(appsContext.state.user){
            const  userInfo  = appsContext.state.user
            // console.log('userInfo: ', userInfo)

            if(typeof userInfo.signInUserSession.idToken.payload[`cognito:groups`] != 'undefined'){
              uGroups = userInfo.signInUserSession.idToken.payload[`cognito:groups`]
              // console.log('uGroups:',uGroups)
              // console.log('uRoles: ', uRoles)
              if( uGroups.some(r => ['Admins'].indexOf(r) >= 0) ){
                // console.log('Admin user.')
                 appsContext.dispatch({ type: "ISADMIN", isAdmin:true }) 
               }
              if( uGroups.some(r => ['Uploaders'].indexOf(r) >= 0) ){
                // console.log('Uploader user.')
                 appsContext.dispatch({ type: "ISUPLOADER", isUploader:true }) 
               }
              // console.log('isAdmin:', isAdmin)
            }

         
          }
        }

        return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  isMounted = false
                  
                };

  },[])

	useEffect(() => {
	
        try{  
          let isMounted = true
          
          if(isMounted === true){
           if(appsContext.state.searchform.terms){
              Analytics.record({
                name: 'SEARCHPAGE', 
                // Attribute values must be strings
                attributes: { terms: appsContext.state.searchform.terms , category: appsContext.state.searchform.category }
              })
              
               getSearchAssetsResults()

            }

          }

          // if(appsContext.state.user){
          //   const  userInfo  = appsContext.state.user
          //   // console.log('userInfo: ', userInfo)
          //   const uRoles = userInfo.attributes[`custom:roles`].split(',')
          //   //user roles checks
          //   if( uRoles.some(r => ['videoAdmin','superAdmin'].indexOf(r) >= 0) ) appsContext.dispatch({ type: "ISADMIN", isAdmin:true })  // checks for one or more permissions to allow access to specific components
          // }

            // getCachedAssetData()
            
            // if( isAdmin ){
              // console.log('pcn video db data...')
              // getAssetData();
            // getAssetSearchData()
           

            // }

            // getData();
              
              // const subscription = API.graphql(graphqlOperation(onCreateAlbum)).subscribe({
             //    next: eventData => {
             //      const album = eventData.value.data.onCreateAlbum;
             //      dispatch({ type: "SUBSCRIPTION", album });
             //    }
             //  });

              return () => {
                //console.log('state2: ', state)
                // subscription.unsubscribe();

              };
        } catch (error){
          // console.log('useEffect error:' , error)
        }


					}, [ appsContext.state.searchform.time ]);


  async function getSearchResultList() {
    
    // console.log('getting getMyProxyList...')
      try{
        // console.log('userInfo.username:', userInfo.username)
        // console.log('appsContext.state.proxyListNextToken:',appsContext.state.proxyListNextToken)
        // const proxyList = await API.graphql(graphqlOperation(proxyListIndexQuery,{category: "Ingest",filter: {or :[{archived: {eq: 0}}, {category: {eq:"Ingest"}}]}, limit: 9999999, nextToken: appsContext.state.proxyListNextToken}));
        // const proxyList = await API.graphql(graphqlOperation(proxyListQuery,{filter: {archived: {eq: 0}}, limit: 999999, nextToken: null}));
        // const proxyList = await API.graphql(graphqlOperation(ListShotAssetsDeltasUploaderIndex,{uploader: appsContext.state.user.username ,category: "Ingest", filter: null, limit: 100, nextToken: appsContext.state.proxyListNextToken}));
        const searchList = await API.graphql(graphqlOperation(listShotwireAssetsDeltas,{uploader: appsContext.state.user.username ,category: {eq: "Ingest"}, filter: null, limit: 100, nextToken: appsContext.state.searchListNextToken}));

        // console.log('proxyList:', proxyList)
        appsContext.dispatch({ type: "SEARCHLIST", searchListConnection: {items: searchList.data.listAssetsByUploader.items, searchListNextToken: appsContext.state.searchListNextToken} });
        



      }catch(err){
        
        //add an image placehold for assets with no image
        // appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
        // console.log('proxy error: ', err)
      }
      

    }

  async function getSearchAssetsByCategory() {    
    // console.log('getting assets...')
      try{
        // console.log('currentCategory:', currentCategory)
      // const albumDataCached = await API.graphql(graphqlOperation(ListShotwireAssetsDeltas,{filter: {category:{contains: "red carpet"}}, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken}));
        // console.log('appsContext.state.cacheLimit:', appsContext.state.cacheLimit)
        // const albumDataCached = await API.graphql(graphqlOperation(listAssetsByCategory,{ category: category, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken}));
        // console.log('appsContext.state.user->', appsContext.state.user)
        const albumSearchResults = await API.graphql(
          {
              query: listAssetsByCategoryPublic,
              variables: { category: appsContext.state.searchform.category, filter:{caption:{contains:appsContext.state.searchform.terms},marketplace:{ne:false}},limit: appsContext.state.searchLimit, nextToken: appsContext.state.searchNextToken },
              authMode: ( (appsContext.state.isAdmin===true || appsContext.state.isUploader===true  || appsContext.state.user!==null )  ? 'AMAZON_COGNITO_USER_POOLS': 'AWS_IAM')
            }
          );
        

        // console.log('albumDataCached:', albumDataCached)
        appsContext.dispatch({ type: "QUERY", albumS: albumSearchResults.data.listAssetsByCategory.items});
        
        // console.log('albumsByCategory:', appsContext.state.albumsByCategory)
      }catch(err){        
        //add an image placehold for assets with no image
        // appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
        // console.log('get assets err: ', err)
      }      
  }

  function capital_letter(str) 
  {
      str = str.split(" ");

      for (var i = 0, x = str.length; i < x; i++) {
          str[i] = str[i][0].toUpperCase() + str[i].substr(1);
      }

      return str.join(" ");
  }

  function searchFilter(str){
    
    // const ogStr = str
    
    const strArr = str.split(" ")
    // console.log('strArr:', strArr)
    
    const capStr = capital_letter(str)
    // console.log('capStr:', capStr)

    const ogArr = capStr.split(" ")

    const search = [...strArr, ...ogArr]
    // console.log('search:', search)

    let queryArr = []

    search.forEach((value) => {
      
      queryArr.push({caption:{contains:value}})
    
    })   
    // console.log('queryArr:', queryArr)
    
    return queryArr

  }

  async function getSearchAssetsResults() {    
    // console.log('getting search assets results...')
      try{
        // // console.log('currentCategory:', currentCategory)
        // // const albumDataCached = await API.graphql(graphqlOperation(ListShotwireAssetsDeltas,{filter: {category:{contains: "red carpet"}}, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken}));
        // // console.log('appsContext.state.cacheLimit:', appsContext.state.cacheLimit)
        // // const albumDataCached = await API.graphql(graphqlOperation(listAssetsByCategory,{ category: category, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken}));
        // console.log('appsContext.state.user->', appsContext.state.user)
        // console.log('isAdmin,isUploader:',appsContext.state.isAdmin,appsContext.state.isUploader)

        // console.log('terms:', appsContext.state.searchform.terms)
        
        // do terms filtering here? capitalize first char of all words? to deal with case sens. indexes?
        const termsArr = searchFilter(appsContext.state.searchform.terms)
        // console.log('termsArr:', termsArr)


//filter:{and:[{headline:{contains:"Travolta"},headline:{contains:"John"}} ]}
        const albumSearchResults = await API.graphql(
          {
              query: listShotwireAssetsDeltasPublic,
              // variables: { filter:{ caption:{ contains:terms}, marketplace:{ne:"false"} },limit: appsContext.state.searchLimit, nextToken: appsContext.state.searchNextToken }, 
              variables: { filter:{ or:[ ...termsArr ], marketplace:{ne:"false"} },limit: appsContext.state.searchLimit, nextToken: appsContext.state.searchNextToken },
              authMode: ( (appsContext.state.isAdmin===true || appsContext.state.isUploader===true  || appsContext.state.user!==null )  ? 'AMAZON_COGNITO_USER_POOLS': 'AWS_IAM')
            }
          );
        

        // console.log('albumSearchResults:', albumSearchResults)
        // appsContext.dispatch({ type: "CATEGORYCACHE", albumConnection: albumDataCached.data.listAssetsByCategory, category:category });
        appsContext.dispatch({ type: "QUERY", albums: albumSearchResults.data.listShotwireAssetsDeltas.items })
        
        // console.log('albumsByCategory:', appsContext.state.albumsByCategory)
      }catch(err){        
        //add an image placehold for assets with no image
        // appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
        // console.log('search assets err: ', err)
        if(err === 'No current user'){
          console.log('removing user settings.')
          appsContext.dispatch({ type: "ISADMIN", isAdmin:false })
          appsContext.dispatch({ type: "ISUPLOADER", isUploader:true }) 
        }
        
      }      
  }

	// async function getAssetSearchData() {
		
	// 	// console.log('searching assets...')
	//     try{
        

 //        // const searchProps = state.searchform = (location.state.searchform) ? location.state.searchform : { terms: "+premiere +event"}
 //        // const searchProps = (location.state.searchform) ? location.state.searchform : appsContext.state.searchform
 //        // const searchProps  = (typeof appsContext.state.searchform !== 'undefined' && appsContext.state.searchform.terms !== '') ?  appsContext.state.searchform : location.state.searchform
 //        // console.log('searchProps:', searchProps)
	//     	// if(appsContext.state.isAdmin!=true ){
 //        if(appsContext.state.user == null ){
 //            appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,suppCat:`Login Required For Complete Searching`, headline:`Live search is only available to authenticated users. Results below may not be complete or accurate.`}] })
 //        }
 //        else if(appsContext.state.searchform.terms !== ''){
 //          // console.log('location.state.searchform', location.state.searchform)
 //        // console.log('appsContext.state.searchform:', appsContext.state.searchform)
          
 //              const albumData = await API.graphql(graphqlOperation(ListAllAssets,{coId:"6B3E1A00BDAB94E4DA166A30A759D44C",  searchStr:appsContext.state.searchform.terms, searchType:"caption", start: appsContext.state.start, limit: appsContext.state.limit}));      
 //               //  // console.log('albumData:', albumData)
 //              appsContext.dispatch({ type: "QUERY", albums: albumData.data.listAllAssetsFTSearch })
              
 //              // albumData.data.listAllAssetsFTSearch.forEach( async (assetO) => {
 //              //   // addAssetDelta(assetO)
 //              //   updateAssetDelta(assetO)
 //              // })

 //        }else appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`No results`, headline:`No results`}] })

	//     }catch(err){
	    	
	//     	//add an image placehold for assets with no image
	//     	appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,suppCat:`Oops!`, headline:`Search is not responding at the moment, please try again in a few minutes.`}] })
	//     	// console.log('error: ', err)
	//     }
	    

	// }

  //   async function getCachedAssetData() {    
  //   // console.log('getting cached assets...')

  //     try{
  //       // console.log('appsContext.state.searchform:',appsContext.state.searchform)
  //       // console.log('currentCategory:', currentCategory)
  //     const albumDataCached = await API.graphql(graphqlOperation(ListShotAssetsDateDeltasIndex,{category: appsContext.state.searchform.category, filter: { caption:{contains:appsContext.state.searchform.terms} }, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken}));
  //       // console.log('appsContext.state.cacheLimit:', appsContext.state.cacheLimit)
  //       // const albumDataCached = await API.graphql(graphqlOperation(ListShotAssetsDateDeltasIndex,{category:currentCategory,filter: null, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken, scannedCount: appsContext.state.scannedCount}));

  //       // console.log('albumDataCached:', albumDataCached)
  //       appsContext.dispatch({ type: "QUERYCACHE", albumConnection: albumDataCached.data.listShotAssetsDateDeltasIndex });
        
  //     }catch(err){        
  //       //add an image placehold for assets with no image
  //       // appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
  //       // console.log('error: ', err)
  //     }      
  // }

  const createAssetDelta = `mutation createAssetDelta($input: CreateShotwireAssetsDeltaInput!) {
      createShotwireAssetsDelta(input: $input) {
        id
        assetId
        name

      }
    }
    `;

  async function addAssetDelta(assetObj) {
    try{
     
      const asset = {...assetObj, archived: 1, category: currentCategory}
       
      Object.keys(asset).forEach((key) => (asset[key] == null||asset[key] == '') && delete asset[key]);
      // console.log('asset->', asset)
      await API.graphql(graphqlOperation(createAssetDelta, { input: asset }));
    } catch(err) {
      // console.log('delta err:',err)
    }
    
  }

  const assetDeltaUpdate = `mutation updateAssetDelta($input: UpdateShotAssetsDeltaInput!) {
      updateShotAssetsDelta(input: $input) {
        id
        category
        assetId
        name
      }
    }
    `;

  async function updateAssetDelta(assetObj) {
    try{
      // console.log('date:', assetObj.setDate)
      const formattedDate = [assetObj.setDate.slice(0, 4), assetObj.setDate.slice(4,6), assetObj.setDate.slice(6,8)].join('-')
      // console.log('formattedDate:', formattedDate)
      const eventDate = new Date(formattedDate).getTime()
      // console.log('eventDate:',eventDate)
      // const asset = {...assetObj, archived: 1, category: currentCategory, timestamp: eventDate }      
      const asset = {...assetObj, archived: 1, searches: appsContext.state.searchform.terms, timestamp: eventDate}      
      Object.keys(asset).forEach((key) => (asset[key] == null||asset[key] == '') && delete asset[key]);
      // console.log('asset->', asset)
      await API.graphql(graphqlOperation(assetDeltaUpdate, { input: asset }));
    } catch(err) {
      // console.log('delta err:',err)
    }    
  }


  return(
  	
<Layout>

 <Container>
 <Row>
 <Col sm={12} lg={12} className="d-md-none d-lg-none  d-xl-none" style={{textAlign:"center"}}>
 
  <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=mostwishforitem&banner=00TMGBBBQ7NZSRVZZX02&f=ifr&linkID=d4e67873703495aac2575c8df9edfcf0&t=shotwire-20&tracking_id=shotwire-20" width="320" height="50" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe> 
  </Col>
  <Col sm={12} lg={12} className="d-none d-md-block d-lg-block d-xl-block">
   <div sx={{
    paddingTop:'15px',
    width:'100%',
    height:'100px',
    textAlign:'center'
    // border:'1px solid white'
    // paddingLeft: '10px',
    // paddingTop: '20px'
   }}>

  <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primemain&banner=1FM3MKXZNJ0PPEBRV2R2&f=ifr&linkID=97f0c247004f7548309281f40eeb7940&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
   </div>
  </Col>
 </Row>
 <Row>
 <div sx={{
  paddingLeft: '10px',
  paddingTop: '20px'
 }}>
 <h3>Search In: "{appsContext.state.searchform.category}" FOR "{appsContext.state.searchform.terms}" </h3>
 </div>
 </Row>
 <Row>
 <div sx={{
  padding: '10px',
 }}>
<ButtonToolbar aria-label="Pagination Tool Bar">
  <ButtonGroup size="sm" className="mr-2" aria-label="First group">
  
  <Button variant="secondary" onClick={e => handlePrevPageClick(e, appsContext.state.activePage)}>
    <FontAwesomeIcon
          icon={faAngleLeft}
          size="sm"
          sx={{
            // marginRight: '0.5rem'
          }}
        />
     </Button>
		{pages.map(page => (
        <Button
          key={page}
          variant="secondary"
          active={page === appsContext.state.activePage}
          onClick={e => handlePageClick(e, page)}
          // disabled={state.currentPage <= 0}
         >
          {page}
        </Button>
      	))
		}
    <Button variant="secondary" onClick={e => handleNextPageClick(e, appsContext.state.activePage)}>
    <FontAwesomeIcon
          icon={faAngleRight}
          size="sm"
          sx={{
            // marginRight: '0.5rem'
          }}
        />
     </Button>     

  </ButtonGroup>
</ButtonToolbar>
</div>
 </Row>

 	  <Row>
  { appsContext.state.isLoading ? (
          <div sx={{
  paddingLeft: '10px',
 }} >
         <Spinner animation="border" variant="secondary" />
     {   
      // <FontAwesomeIcon
      //       icon={faSpinner}
      //       size="sm"
      //       sx={{
      //         // marginRight: '0.5rem'
      //       }}
      //       spin
      //     />
        }
          </div>
        ) : (
          <div sx={{
  paddingLeft: '10px',
 }}></div>
        )
    }
  </Row>
 	
        <Row sx={{
          // borderTop: '1px solid rgba(0,0,0, .5)'
        }}>
        <Flex
          sx={{
            flexWrap: 'wrap'
          }}
        >
        
        {	

          

          appsContext.state.albums.map((asset, key) => (

                <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
                  <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={
                    (asset.thumbNailUrl!=null)?asset.thumbNailUrl:require(`../@geocine/gatsby-theme-organization/assets/shotwire-logo.png`) 
                  }>
            [ { (asset.archived ? Math.trunc(asset.video_length/60) +':'+Math.round((asset.video_length/60).toString().substr((asset.video_length/60).toString().indexOf('.'),3)*60):'pending') } ]{'   '}{asset.city}<br />
            { (asset.headline) ? asset.headline.substr(0,100):(asset.archived ? 'missing headline':asset.name) } 
                  </VideoCard>
                </Box>
              )
          )
        

      //   	appsContext.state.albums.map((asset, key) => (

		    //         <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
		    //           <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={`https://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/${asset.assetId}.jpg`}>
						// [ {asset.video_length} ]{'   '}{asset.city}<br />
						// { asset.headline.substr(0,100) } 
		    //           </VideoCard>
		    //         </Box>
		    //       )
      //   	)
        }
        </Flex>
        </Row>

        <Row>
                 <Col sm={12} lg={12} className="d-md-none d-lg-none  d-xl-none" style={{textAlign:'center'}}>
                 
                  <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=amazonhomepage&f=ifr&linkID=71d3093c671662bd39eab6a15e1dd5e3&t=shotwire-20&tracking_id=shotwire-20" width="320" height="50" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>  
                  </Col>
                  <Col sm={12} lg={12} className="d-none d-md-block d-lg-block d-xl-block">
                   <div sx={{
                    paddingTop:'15px',
                    width:'100%',
                    height:'100px',
                    textAlign:'center'
                    // border:'1px solid white'
                    // paddingLeft: '10px',
                    // paddingTop: '20px'
                   }}>

                  <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=camera&banner=1NA6DKA4BJJ7G5WRRJG2&f=ifr&linkID=7d7eab64c3e09ca67cb82b1cc163e07f&t=shotwire-20&tracking_id=shotwire-20" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{border:"none"}} frameBorder="0"></iframe>
                   </div>
                  </Col>
                 </Row>
         ***
       
        <Row sx={{
          // borderTop: '1px solid rgba(0,0,0, .2)'
        }}>
        { 

          // appsContext.state.albumsCached.map((asset, key) => (

          //       <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
          //         <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={
          //           (asset.assetId)?
          //           `https://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/${asset.assetId}.jpg`
          //           :`https://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/undefined.jpg`
          //         }>
          //   [ { (asset.archived ? asset.video_length:'pending') } ]{'   '}{asset.city}<br />
          //   { (asset.headline) ? asset.headline.substr(0,100):(asset.archived ? 'missing headline':asset.name) } 
          //         </VideoCard>
          //       </Box>
          //     )
          // )
        }

        </Row>
        <Row>
        <div sx={{
        padding: '10px'
       }}>
<ButtonToolbar aria-label="Pagination Tool Bar">
  <ButtonGroup size="sm" className="mr-2" aria-label="First group">
  
  <Button variant="secondary" onClick={e => handlePrevPageClick(e, appsContext.state.activePage)}>
    <FontAwesomeIcon
          icon={faAngleLeft}
          size="sm"
          sx={{
            // marginRight: '0.5rem'
          }}
        />
     </Button>
		{pages.map(page => (
        <Button
          key={page}
          variant="secondary"
          active={page === appsContext.state.activePage}
          onClick={e => handlePageClick(e, page)}
          // disabled={state.currentPage <= 0}
         >
          {page}
        </Button>
      	))
		}
    <Button variant="secondary" onClick={e => handleNextPageClick(e, appsContext.state.activePage)}>
    <FontAwesomeIcon
          icon={faAngleRight}
          size="sm"
          sx={{
            // marginRight: '0.5rem'
          }}
        />
     </Button>     
  </ButtonGroup>
</ButtonToolbar>
</div>
        </Row>
      </Container>
 </Layout>
 )
}


// <Layout>
//     <SEO title="Celebrity Candids" />
//     <h1>Hi from the second page</h1>
//     <p>Celebrity Candids</p>
//     <Link to="/">Go back to the homepage</Link>
//   </Layout>

export default SearchPage